import { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import _ from "lodash";

import 'react-toastify/dist/ReactToastify.css';

export const urlServer = "https://mobilelab.ferit.hr/";
// export const urlServer = "http://localhost:3002/";

export const useFetch = (method, url, initialParams = {}, resolve, reject) => {
  const [params, updateParams] = useState(initialParams);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const queryString = method==='POST' ? "" : Object.keys(params)
      .map((key) => encodeURIComponent(key) + '=' +
      encodeURIComponent(params[key])).join('&');
  useEffect(() => {
    if (_.isEmpty(params)) return;
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const body = await JSON.stringify(params);
        const response = method==='POST'
          ? await fetch(url, {method, body, headers: {
            'Content-Type':'application/json'}})
          : await fetch(`${url}?${queryString}`,{method});
        const result = await response.json();
        if (response.ok) {
          resolve(result);
          setData(result);
        } else {
          setHasError(true);
          setErrorMessage(result);
          reject();
        }
      } catch (err) {
        setHasError(true);
        setErrorMessage(err.message);
        reject();
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [params]);
  return { data, isLoading, hasError, errorMessage, updateParams };
}

export function notify(level, text) {
  let fun = toast.info;
  switch(level) {
    case "info":    fun = toast.info;    break;
    case "warning": fun = toast.warning; break;
    case "success": fun = toast.success; break;
    case "error":   fun = toast.error;   break;
    default:        fun = toast;         break;
  }
  const options = {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    autoClose: 3000,
    pauseOnHover: false
  }
  fun(text,options);
}
