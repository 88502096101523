import React, { useState } from "react";
import {notify, useFetch, urlServer} from "constants.js";
import _ from "lodash";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import People from "@material-ui/icons/People";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/loginStyle.js";

const useStyles = makeStyles(styles);

const initialState = {
  type: '0',
  ip:   "",
  user: "",
  pass: ""
};

var md5 = require('md5');

export default function SectionLogin(props) {
  const [state, setState] = useState({...initialState, ip: props.ip});
  const { data, updateParams } = useFetch("POST",urlServer+'auth',{},resolve,reject);
  const handleSubmit = event => {
    event.preventDefault();
    if(props.logged) {
      notify("info","Odjavljen korisnik: " + data[0].user);
      props.setLogged(false);
    } else {
      if(validateForm()) {
        updateParams({
          type: '0',
          ip:   props.ip,
          user: state.user,
          pass: md5(state.pass)
        });
      } else {
        notify("warning", "Popunite sva polja");
      }
    }
  }
  function resolve(r) {
    if(!_.isEmpty(r)) {
      if(r.info===0) {
        notify("error","Dosegli ste maksimum pokušaja");
        props.setLogged(false);
      } else if(r.info===2) {
        notify("error","Greška na serveru");
        props.setLogged(false);
      } else {
        notify("success", "Prijavljen korisnik: " + r[0].user);
        props.setLogged(true);
      }
    } else {
      notify("error", "Prijava nije uspjela");
    }
    setState(initialState);
  }
  function reject() {
    notify("error", "Prijava nije uspjela");
  }
  function validateForm() {
    return state.user!=="" && state.pass!=="";
  }
  const onChange = e => {
    const { id, value } = e.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <form className={classes.form} onSubmit={handleSubmit}>
                <CardHeader color="info" className={classes.cardHeader}>
                  <h4>Login</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="User name"
                    id="user"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: state.user,
                      disabled: props.logged,
                      onChange: onChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <People className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="pass"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      value: state.pass,
                      disabled: props.logged,
                      onChange: onChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputIconsColor}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      autoComplete: "off"
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button simple color="primary" size="lg" type="submit">
                    {props.logged ? "Logout" : "Login"}
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
