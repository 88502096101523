import React from 'react';
import PropTypes from 'prop-types';
import { FlagIcon } from "react-flag-kit";

export function LanguageSelect(props) {
  const {lang, size} = props;
  return (
    <FlagIcon code={lang === "hr" ? "HR" : "US"} size={size} />
  );
}

LanguageSelect.defaultProps = {
  size: 35,
  lang: "hr"
};

LanguageSelect.propTypes = {
  size: PropTypes.number,
  lang: PropTypes.oneOf(["hr","gb"])
};
