import React, {useState} from "react";
import _ from "lodash";
import {notify, useFetch, urlServer} from "constants.js";
import Carousel from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
import LocationOn from "@material-ui/icons/LocationOn";
import { Divider } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
const useStyles = makeStyles(styles);
let n = [];

const initialArticleState = {
  showArticleForm: false,
  titleHr: "",
  titleGb: "",
  textHr: "",
  textGb: "",
  link: "",
  location: "",
  images: [],
  fileNames: [],
};

export default function SectionNews(props) {
  const [state, setState] = useState({});
  const [stateArticle, setStateArticle] = useState(initialArticleState);
  const { hr, logged } = props;
  const { updateParams } = useFetch("POST",urlServer+'news',{type:"1"},resolve,reject);
  async function resolve(r) {
    //console.log("After resolve");
    const {type} = r;
    if(type === "1") {
      delete r.info;
      delete r.type;
      n = [];
      _.forEach(r,(article) => {
        const id = article.id;
        const titleHr = article.titleHr
        const titleGb = article.titleGb;
        const textHr = article.textHr
        const textGb = article.textGb;
        const link = article.link;
        const location = article.location;
        const images = Buffer.from(article.images.data).toString().split("@");
        n.push({id,titleHr,titleGb,textHr,textGb,link,location,images});
      });
      setState(n);
    } else if (type === "2") {
      notify("info", "Postavili ste obavijest");
      updateParams({
        type: '1',
        id: null
      });
      setStateArticle({...initialArticleState});
    } else if (type === "4") {
      notify("info", "Obavijest je obrisana");
      updateParams({
        type: '1',
        id: null
      });
    }
  };
  function reject() {
    //console.log("After reject");
    notify("error", "Ups, došlo je do greške");
    n=[];
    setState(n);
    setStateArticle({...initialArticleState});
  };
  const handleDelete = e => {
    updateParams({
      type: '4',
      id: e.target.id
    });
  }
  const handleNewArticle = e => {
    //console.log("new article");
    setStateArticle((prevState) => ({
      ...prevState, showArticleForm: true}));
  }
  const handleCancel = e => {
    //console.log("cancel");
    setStateArticle({...initialArticleState});
  }
  const handleChange = e => {
    //console.log("change");
    const { id, value } = e.target;
    setStateArticle(prevState => ({ ...prevState, [id]: value }));
  }
  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    //console.log("submit");
    updateParams({
      ...stateArticle,
      type: '2',
    });
  }
  const readFile = file => {
    return new Promise((resolve, reject) => {
      let fr = new FileReader();
      fr.onload = x=> resolve(fr.result);
      fr.readAsDataURL(file) // or readAsText(file) to get raw content
  })}
  const handleChangeImage = async (e) => {
    let images = [];
    for(let [i,f] of [...e.target.files].entries() ) {
      const uploadedImage = await readFile(f);
      images.push(uploadedImage);
    }
    setStateArticle((prevState) => ({
      ...prevState, images
    }));
  }
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };
  const showArticleForm = stateArticle.showArticleForm && logged;
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <div>{hr?"Novosti":"News" }{(logged && !showArticleForm) &&
            <button className={classes.loggedButton} onClick={handleNewArticle}>
            Nova obavijest</button>}</div>
        </h2>
        {showArticleForm &&
          <form onSubmit={handleSubmit}>
            <input id="titleHr" type="text" placeholder="titleHr" onChange={handleChange}/>
            <input id="titleGb" type="text" placeholder="titleGb" onChange={handleChange} />
            <input id="textHr" type="text" placeholder="textHr" onChange={handleChange} />
            <input id="textGb" type="text" placeholder="textGb" onChange={handleChange} />
            <input id="location" type="text" placeholder="location" onChange={handleChange} />
            <input id="link" type="text" placeholder="link" onChange={handleChange} />
            <input id="file" type="file" placeholder="slika" onChange={handleChangeImage}
                   encType="multipart/form-data" accept="image/*" required multiple/>
            <button onClick={handleCancel} >Cancel</button>
            <button type="submit" >Submit</button>
          </form>
        }
        {_.isEmpty(state) ?
          <p>Loading news...</p>
          :
          <GridContainer justify="top" >
            {_.map(state,(article,index) => {
              return(
                <GridItem key={index} xs={12} sm={12} md={6} lg={4}>
                  <Card carousel>
                    <CardHeader>
                      <br/><h3><strong>{hr?article.titleHr:article.titleGb}</strong></h3>
                      <h4>{hr?article.textHr:article.textGb}</h4>
                      <h4><a href={article.link} className={classes.inlineBlock}
                        target="_blank" rel="noopener noreferrer">Poveznica</a>
                        {logged &&
                        <button className={classes.loggedButton}
                          id={article.id} onClick={handleDelete}>
                          Obriši obavijest</button>
                        }
                        </h4>
                    </CardHeader>
                    <Divider />
                    { <Carousel {...settings}>
                      {article.images.map((i,_index) => {return(
                          <div key={_index}>
                            <img src={`${i}`} alt="" className="slick-image" />
                            <div className="slick-caption">
                              <h4>
                                <LocationOn className="slick-icons" />
                                {article.location}
                              </h4>
                            </div>
                          </div>
                      )})}
                    </Carousel>
                  }
                  </Card>
                </GridItem>
          )})}
          </GridContainer>
      }
      </div>
    </div>
  );
}
