import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import List from "@material-ui/icons/List";
//import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
//import EcoOutlinedIcon from '@material-ui/icons/EcoOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import EuroOutlinedIcon from '@material-ui/icons/EuroOutlined';
import GroupIcon from '@material-ui/icons/Group';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionProject(props) {
  const {t} = props;
  const projectTeam = t.hr?"Projektni tim":"Project team";
  const classes = useStyles();
  function _link(text, href, target="_blank", rel="noopener noreferrer") {
    return <a className={classes.text} href={href} target={target} rel={rel}>{text}</a>
  }
  return (
    <div className={classes.section}>
      <div className={classes.container}>
          <div className={classes.title}>
            <h2>{t.about}</h2>
          </div>
          <div  className={classes.text}>
            <h4>
              <br/><br/>
              {(t.hr?"Naziv projekta":"Project title")+": "+t.titleLong}
              <br/><br/>
              {t.hr?"Organizacije koje sudjeluju na projektu su:"
                   :"The scientific organizations participating in the project are:"}
              <br/>
              <ul>
                <li><h4>{(t.hr?"Financijer":"Financing")+": "+t.us_emb}</h4></li>
                <li><h4>{(t.hr?"Provoditelj":"Executing")+": "+t.ferit}</h4></li>
              </ul>
              <br/>
              {t.hr?"Trajanje projekta: 26.10.2020. - 01.11.2021.":"Project duration: 26 October 2020 - 01 November 2021"}
              <br/><br/>
              {t.hr?"Ukupna vrijednost projekta: 90.830,00 HRK":"Total project value: USD 14.834,00"}
              <br/><br/>
              {t.hr?"Iznos US sufinanciranja: 90.830,00 HRK":"Amount of US financing: USD 14.834,00"}
              <br/><br/>
              {(t.hr?"Izvor financiranja":"Financing source")+": "+t.us_emb}
              <br/><br/>
            </h4>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8} lg={6}>
              <NavPills
                color="primary"
                tabs={[
                  {
                    tabButton: t.hr?"Svrha projekta":"Justification",
                    tabIcon: PlayCircleFilledWhiteOutlinedIcon,
                    tabContent: (
                      <span>
                        <br />
                        <p>{t.justification}</p>
                      </span>
                    )
                  },
                  {
                    tabButton: t.hr?"Samoodrživost":"Sustainability",
                    tabIcon: EuroOutlinedIcon,
                    tabContent: (
                      <span>
                        <br />
                        <p>{t.sustainability}</p>
                      </span>
                    )
                  },
                  {
                    tabButton: t.hr?"Ciljevi":"Goals",
                    tabIcon: List,
                    tabContent: (
                      <span>
                        <p>{t.hr?"Ciljevi":"Goals"}</p>
                        <div>
                          <ul>
                            <li>{t.goal1}</li>
                            <li>{t.goal2}</li>
                            <li>{t.goal3}</li>
                            <li>{t.goal4}</li>
                          </ul>
                        </div>
                        <br/>
                        <p>{t.goal5}</p>
                      </span>
                    )
                  }
                ]}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={6}>
              <NavPills
                color="info"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 4, md: 4 },
                  contentGrid: { xs: 12, sm: 8, md: 8 }
                }}
                tabs={[
                  {
                    tabButton: t.hr?"Doprinos":"Contribution",
                    tabIcon: DashboardOutlinedIcon,
                    tabContent: (
                      <span>
                        <p>{t.contribution}</p>
                        <br />
                      </span>
                    )
                  },
                  {
                    tabButton: t.hr?"Rezultati":"Results",
                    tabIcon: WbIncandescentOutlinedIcon,
                    tabContent: (
                      <span>
                        <p>{t.results}</p>
                        <br />
                      </span>
                    )
                  },
                  {
                    tabButton: projectTeam,
                    tabIcon: GroupIcon,
                    tabContent: (
                      <span>
                        <p><strong>{_link(projectTeam+" FERIT Osijek","https://www.ferit.unios.hr/")}</strong></p>
                        <p>{_link((t.hr?"izv.prof.dr.sc.":"Prof.")+" Tomislav Matić, "+(t.hr?"":"PhD ")+t.projectManager,"https://www.ferit.unios.hr/fakultet/imenik-djelatnika/tmatic1")}</p>
                        <p>{_link((t.hr?"doc.dr.sc.":"Prof.")+" Zdravko Krpić"+(t.hr?"":", PhD"),"https://www.ferit.unios.hr/fakultet/imenik-djelatnika/krpic")}</p>
                        <p>{_link((t.hr?"izv.prof.dr.sc.":"Prof.")+" Ivan Aleksi"+(t.hr?"":", PhD"),"https://www.ferit.unios.hr/fakultet/imenik-djelatnika/ialeksi")}</p>
                        <p>{_link("Filip Sušac, mag.ing.comp.","https://www.ferit.unios.hr/fakultet/imenik-djelatnika/fsusac")}</p>
                      </span>
                    )
                  }
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
  );
}
