export const languageText = {
  titleShort:{hr:"Mobilni laboratorij",gb:"Mobile Laboratory"},
  titleLong:{hr:"VHDL/FPGA mobilni učiteljski laboratorij",gb:"VHDL/FPGA Mobile Teaching Laboratory"},
  euFund:{hr:"U.S. Veleposlanstvo Zagreb, Hrvatska",gb:"U.S. Embassy Zagreb, Croatia"},
  financed: {hr: "Projekt financira Američko veleposlanstvo u Hrvatskoj", gb: "The project was financed by the U.S. Embassy in Croatia"},
  content:{hr:"Sadržaj",gb:"Content"},
  goal1:{hr:"Opremiti mobilni učiteljski laboratorij sa svom potrebnom opremom i programskim alatima.",
         gb:"Equip the mobile teaching laboratory with all the necessary hardware and software."},
  goal2:{hr:"Izraditi nastavne materijale (prezentacije, VHDL kod, programske upute) za radionice.",
         gb:"Develop teaching materials (presentations, VHDL code, program instructions) for workshops."},
  goal3:{hr:"Povećanje zanimanja za STEM među osnovnoškolcima i srednjoškolcima.",
         gb:"Increasing interest in STEM among elementary and high school students."},
  goal4:{hr:"Promocija projekta i mogućih primjena mobilnog učiteljskog laboratorija u što većem broju škola te ostaloj javnosti izradom promotivnih materijala i web stranicom.",
         gb:"Promotion of the project and possible applications of the mobile teacher's laboratory in as many schools as possible and the rest of the public by creating promotional materials and a website."},
  goal5:{hr:"Kao jedan od mogućih ciljeva je promocija projekta i mogućih primjena mobilnog nastavničkog laboratorija u većem broju škola i javnosti. Ostvaranje ovog cilja je moguće izradom promotivnih olovaka, rokovnika, roll-up plakata itd.",
         gb:"As one of the possible goals is promoting the project and possible applications of the mobile laboratory in a larger number of schools and to the public. Realisation of this goal is possible by creating promotional pencils, memo pads, roll-up banners, etc."},
  justification: {
    hr:"U Hrvatskoj nedostaje osoblja iz svih STEM kategorija. Potrebno je povećati zanimanje za STEM među mladima kako bi se taj broj povećao u budućnosti. Predloženi mobilni laboratorij omogućuje izvođenje vježbi dizajniranja digitalnih sustava u školskim učionicama. Nema potrebe da učenici putuju na fakultet. Naše ciljne skupine su učenici škola iz slavonske regije smještene u Vukovarsko-srijemskoj, Brodsko-posavskoj, Virovitičko-podravskoj i Požeško-slavonskoj županiji (Županja, Ilok, Sl. Brod, Požega, Virovitica, Nova Gradiška).",
    gb:"In Croatia there are shortages in personnel from all STEM categories. It is necessary to increase interest in STEM among youth to increase this number in the future. Proposed mobile laboratory will enable to perform digital systems design exercises in school classrooms. There is no need for pupils to travel to the Faculty. Our target groups are pupils from schools in Slavonia region located in Vukovar-Srijem, Brod-Posavina, Virovitica-Podravina and Požega-Slavonia County (Županja, Ilok, Sl. Brod, Požega, Virovitica, Nova Gradiška)."},
  sustainability: {
    hr:"Projekt nastavnicima pruža priliku korištenja mobilnog laboratorija te prijenosa znanja kroz STEM teme. Web stranica projekta dostupna je sa svim kontaktnim podacima, a učitelji mogu kontaktirati projektni tim i organizirati mobilne vježbe u svojim školama. Djelatnosti mobilnog laboratorija promoviraju se i provode u sklopu redovitih aktivnosti fakulteta.",
    gb:"The project will give the opportunity to faculty teachers to use the mobile laboratory and transfer knowledge for other STEM topics. Project’s web page is available with all contact information, and Teachers are able to contact project’s team and arrange mobile exercises in their schools. Activities of the mobile laboratory are promoted and implemented as part of the regular activities of the faculty."},
  contribution: {
    hr:"U ovom projektu koristimo svoje nastavno iskustvo o VHDL/FPGA tehnologiji za izradu laboratorijskih vježbi prilagođenih osnovnoškolskim i srednjoškolskim programima. Sva potrebna laboratorijska oprema je u posebnim prijenosnim koferima jednostavnim za prenošenje. Radionice će biti organizirane u različitim školama na području Slavonije. Najnovija VHDL/FPGA tehnologija biti će predstavljena učenicima, što se obično ne podučava u školskim programima.",
    gb:"In this project, we use our VHDL/FPGA teaching experience to create digital systems design laboratory exercises adapted to elementary and high school programs. All necessary laboratory equipment will be placed in custom cases that will be easy to carry and transport. Workshops will be arranged in different schools in the Slavonia region. The latest VHDL/FPGA technology will be presented to pupils that are not usually taught in school curriculums."},
  results: {
    hr:"Projekt ima nekoliko rezultata. Prvi rezultat su četiri prilagođena putna kućišta sa svom opremom za mobilni laboratorij. Drugi rezultat je materijal za laboratorijske vježbe (prezentacije, vodiči, VHDL kôd). Konačni rezultat projekta su izvedene vježbe. Učenici će dobiti promotivni materijal i potvrdu o sudjelovanju sa svim informacijama kako bi prepoznali potporu američkog veleposlanstva. Na kraju vježbi učenici će popuniti anketu o vježbama. To će pokazati koliko su učenici zainteresirani za STEM nakon izvedenih vježbi.",
    gb:"The project has several results. The first result is four custom travel cases with all equipment for the mobile laboratory. The second result is the laboratory exercise material (presentations, tutorials, VHDL code). The final result of the project is the performed exercises. Pupils will be given promotional material and confirmation of participation with all information to recognize support from the American Embassy. At the end of the exercises, pupils will fill out a poll about the exercises. This will show how interested are pupils in STEM after the performed exercises."},
  home: {hr:"Početna",gb:"Home"},
  about: {hr:"O projektu",gb:"Project details"},
  partners: {hr:"Partneri projekta",gb:"Project partners"},
  news: {hr:"Novosti",gb:"News"},
  contact: {hr:"Kontakt",gb:"Contact"},
  login: {hr:"Login",gb:"Login"},
  logout: {hr:"Logout",gb:"Logout"},
  webPage: {hr:"Internet stranica",gb:"Web page"},
  ferit: {hr:"Fakultet elektrotehnike, računarstva i informacijskih tehnologija Osijek",gb:"Faculty of Electrical Engineering, Computer Science and Information Technology Osijek"},
  us_emb: {hr:"Američko veleposlanstvo u Hrvatskoj",gb:"U.S. Embassy in Croatia"},
  link: {hr:"Poveznica",gb:"Link"},
  sendMessage: {hr: "Pošalji poruku", gb: "Send message"},
  sendingMessage: {hr: "Slanje...", gb: "Sending..."},
  projectManager: {hr:"voditelj projekta", gb:"project manager"},
  translate: function(lang) {
    let x = {};
    Object.keys(languageText).forEach(i=>x[i]=languageText[i][lang]);
    x.hr = lang==="hr" ? true : false;
    return x;
  }
};
