/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import image1 from "assets/img/logoUSEMB.png";
import image2 from "assets/img/logoMobLab.png";
import image3 from "assets/img/logoFERIT_hr.png";

const imageList = [
  image1, image2, image3
];
import Clearfix from "components/Clearfix/Clearfix.js";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const {hr} = props;
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
  <div className={classes.section}>
    <footer className={footerClasses} style={{backgroundColor: "white"}}>
      <div className={classes.container}>
        <div className={classes.center}>
          <List className={classes.list}>
            {imageList.map((item, index) => {
              return(
                <ListItem key={index} className={classes.inlineBlock}>
                  <img src={item} height="150px"
                    className={classes.block} />
                </ListItem>
            );})}
          </List>
        </div>
      </div>
    </footer>
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.center} style={{color: "Navy", fontSize: "15px"}}>
        {hr ? "Projekt je financiralo Američko veleposlanstvo Zagreb, Hrvatska"
            : "The project was financed by the U.S. Embassy Zagreb, Croatia"}
        <br/>
        {hr ? "Izradu Internetske stranice financiralo je Američko veleposlanstvo Zagreb, Hrvatska"
            : "The website development was financed by the U.S. Embassy Zagreb, Croatia"}
        <br/>
        2021. &copy;
          {hr ? " Sadržaj materijala isključiva je odgovornost Fakulteta elektrotehnike, računarstva i informacijskih tehnologija Osijek"
              : " The content of the material is the sole responsibility of the Faculty of Electrical Engineering, Computer Science and Information Technology Osijek"}
          <br/><a href="https://hr.usembassy.gov/" className={classes.inlineBlock} target="_blank"
          >{hr ? "Američko veleposlanstvo u Hrvatskoj":"U.S. Embassy in Croatia"}</a>
          <br/>
          <br/>
        </div>
      </div>
    </footer>
  </div>);
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
