import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/completedStyle.js";

import image1 from "assets/img/logoFERIT_hr.png";
import image2 from "assets/img/logoUSEMB.png";

const useStyles = makeStyles(styles);

export default function SectionPartneri(props) {
  const {t} = props;
  const classes = useStyles();
  const styleGridItem = {padding: "10px", textAlign: "left", verticalAlign: "top", lineHeight: "1.5em"};
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div className={classes.title} style={{textAlign: "left"}}>
          <h2>{t.partners}</h2>
        </div>
        <GridContainer style={{padding: "40px"}}>
          <GridItem xs={12} sm={12} md={4} lg={3} style={{...styleGridItem, textAlign:"center"}}>
            <h4><strong>{t.hr ? "Projekt financira:" : "Project financing:"}</strong></h4>
            <img src={image2} height="150px" alt="..."/>
          </GridItem>
          <GridItem xs={12} sm={12} md={8} lg={9} style={styleGridItem}>
            <h4><strong>{t.us_emb}</strong>
            <br/>
            {t.webPage+": "}<a href="https://hr.usembassy.gov/"
            target="_blank" rel="noopener noreferrer">https://hr.usembassy.gov/</a>
            <br/>
            </h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} lg={3} style={{...styleGridItem, textAlign:"center"}}>
            <h4><strong>{t.hr ? "Projekt provodi:" : "Project executes"}</strong></h4>
            <img src={image1} height="150px" alt="..."/>
          </GridItem>
          <GridItem xs={12} sm={12} md={8} lg={9} style={styleGridItem}>
            <h4><strong>{t.ferit}</strong>
            <br/>{t.webPage + ": "}<a href="https://www.ferit.unios.hr"
            target="_blank" rel="noopener noreferrer">https://www.ferit.unios.hr</a>
            <br/>
            {t.hr?"Voditelj projekta: izv.prof.dr.sc. Tomislav Matić"
                 :"Head of project: Prof. Tomislav Matić, PhD"}
            <br/>
            {t.hr?"Izvor financiranja: "+t.us_emb
                 :"Financing source: "  +t.us_emb}
            </h4>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
