import React, { useState } from "react";
import {notify, useFetch, urlServer} from "constants.js";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import InputAdornment from "@material-ui/core/InputAdornment";
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import WebOutlinedIcon from '@material-ui/icons/WebOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import CallIcon from '@material-ui/icons/Call';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import image from 'assets/img/logoFERIT_wide_hr.png';
import imageMap from 'assets/img/map_ferit.png';

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";

const  urlMap = "https://www.google.com/maps/place/Faculty+of+Electrical+Engineering,+University+of+Osijek/@45.5575327,18.6948823,16.11z/data=!4m8!1m2!2m1!1sferit+osijek+maps!3m4!1s0x475ce7b9c3d3213d:0x43b5fc47f7e90641!8m2!3d45.556784!4d18.695619";
const useStyles = makeStyles(styles);

const initialState = {
  name: "",
  mail: "",
  message: ""
};

export default function SectionContact(props) {
  const [state, setState] = useState(initialState);
  function resolve(r) {
    if(!_.isEmpty(r)) {
      if(r.info===0 || r.info===2) {
        notify("error", "Dosegli ste maksimum pokušaja.");
      } else {
        notify("success", "Hvala, poslali ste poruku");
      }
    } else {
      notify("error", "Poruka nije poslana");
    }
    setState(initialState);
  }
  function reject() {
    notify("error", "Poruka nije poslana");
  }
  const { isLoading, updateParams } = useFetch("POST",urlServer+'mail',{},resolve,reject);
  const handleSubmit = event => {
    event.preventDefault();
    if(validateForm()) {
      updateParams({
        type: '0',
        ip:   props.ip,
        name: state.name,
        mail: state.mail,
        message: state.message
      });
    } else {
      notify("warning","Popunite sva polja");
    }
  }
  function validateForm() {
    return state.name!=="" && state.mail!=="" && state.message!=="";
  }
  const onChange = e => {
    const { id, value } = e.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  }
  const {t} = props;
  const classes = useStyles();
  const styleRow = {display: "block", verticalAlign: "textTop"};
  const styleGridItem = {textAlign: "left"};
  const styleIcon = {color: "grey", marginTop: "-2px", marginRight: "10px", verticalAlign: "text-top", fontSize: "20px"};
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div className={classes.title}>
          <h2>{t.contact}</h2>
        </div>
        <div className={classes.text}>
          <GridContainer style={{alignItems: "center", justifyItems: "center"}}>
          <GridItem style={styleGridItem} xs={12} sm={12} md={7} lg={7} xl={7}>
            <div style={styleRow}>
              <img src={image} alt="" style={{height: "160px", maxHeight: "22vw", padding: "10px"}}/>
              <br />
              <h4>{t.ferit}</h4>
              <h4>Kneza Trpimira 2B, HR-31000 Osijek</h4>
              <p><WebOutlinedIcon style={styleIcon}/>
                <a href="https://www.ferit.unios.hr/"
                   target="_blank" rel="noopener noreferrer">
                  {"https://www.ferit.unios.hr/"}</a></p>
              <p><EmailOutlinedIcon style={styleIcon}/>mobilelab@ferit.hr</p>
              <p><CallIcon style={styleIcon}/>+385 (0) 31 224-600</p>
            </div>
          </GridItem>
          <GridItem style={styleGridItem} xs={12} sm={12} md={5} lg={5} xl={5}>
            <Card>
            <CardHeader color="info" className={classes.cardHeader}>
              <h4>{t.hr?"Želite nam poslati poruku?":"Whish to send us a message?"}</h4>
            </CardHeader>
            <form className={classes.form} onSubmit={handleSubmit}>
              <CardBody>
                <CustomInput
                  labelText={t.hr?"Vaše ime i prezime":"Your first name and last name"}
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: state.name,
                    onChange: onChange,
                    endAdornment: (
                      <InputAdornment position="end">
                        <PermIdentityOutlinedIcon className={classes.inputIconsColor} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText={t.hr?"Vaša e-mail adresa":"Your e-mail address"}
                  id="mail"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "email",
                    value: state.mail,
                    onChange: onChange,
                    endAdornment: (
                      <InputAdornment position="end">
                        <EmailOutlinedIcon className={classes.inputIconsColor} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText={t.hr?"Vaša poruka":"Your message"}
                  id="message"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    onChange: onChange,
                    value: state.message,
                    multiline: true,
                    rows: 3,
                    endAdornment: (
                      <InputAdornment position="end">
                        <ChatOutlinedIcon className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                <Button simple color="primary" size="lg" type="submit">
                  {isLoading ? t.sendingMessage : t.sendMessage}
                </Button>
              </CardFooter>
            </form>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <div style={{minHeight: "30px"}} />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <a href={urlMap} target="_blank" rel="noopener noreferrer">
              <img src={imageMap} alt="" style={{width: "100%"}}/>
            </a>
          </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
