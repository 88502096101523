import React, { useState } from "react";
import {useFetch} from "constants.js";
import _ from "lodash";
// nodejs library that concatenates classes
import classNames from "classnames";

import { ToastContainer } from 'react-toastify';

import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem } from '@material-ui/core';
import { Divider } from "@material-ui/core";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import {languageText} from "components/Language/languageText.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// sections for this page
import SectionProject from "Sections/SectionProject.js";
import SectionImagesMeasure from "Sections/SectionImagesMeasure.js";
import SectionImagesScience from "Sections/SectionImagesScience.js";
import SectionNews from "Sections/SectionNews.js";
import SectionPartneri from "Sections/SectionPartneri.js";
import SectionLogin from "Sections/SectionLogin.js";
import SectionContact from "Sections/SectionContact.js";

import styles from "assets/jss/material-kit-react/views/components.js";

import image1 from "assets/img/logoUSEMB.png";
import image2 from "assets/img/logoFERIT_hr.png";

const imageList = [
  image1, image2
];

const useStyles = makeStyles(styles);

let ip="";

export default function App(props) {
  const [lang, setLang] = useState("hr");
  const [logged, setLogged] = useState(false);
  const t = languageText.translate(lang);
  const menuItems = [
    {href: "#home",     text: t.home},
    {href: "#project",  text: t.about},
    {href: "#partners", text: t.partners},
    {href: "#news",     text: t.news},
    {href: "#contact",  text: t.contact},
    {href: "#login",    text: logged ? t.logout : t.login}
  ];
  const classes = useStyles();
  const { ...rest } = props;
  const handleLanguage = (e) => {
    e.preventDefault();
    setLang( lang !== "hr" ? "hr" : "gb" );
  }
  useFetch('GET','https://api.ipify.org/',{format:"json"},resolve,reject);
  function resolve(r) {
    if(!_.isEmpty(r)) {
      ip = r.ip;
      console.log(ip);
    }
  }
  function reject() { console.log("ip missing"); }
  if(ip==="") return ( <div>Loading...</div> );
  else return (
    <div>
      <ToastContainer/>
      <div id="home" />
        <Header
          brand=""
          rightLinks = {<HeaderLinks menuItems={menuItems} lang={lang}
                        handleLanguage={handleLanguage} logged setLogged={setLogged}/>}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
      <Parallax image={require("assets/img/bg.jpg")}>
        <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <List style={{
              background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,0) 45%)",
              display: "block"}}>
              {imageList.map((item, index) => {
                return(
                  <ListItem key={index} className={classes.inlineBlock}>
                    <img src={item} alt="" style={{height: "125px", maxHeight: "16vw", padding: "5px"}}
                      className={classes.block} />
                  </ListItem>
              );})}
            </List>
          </GridItem>
          <GridItem>
            <div className={classes.brand} style={{textShadow: "2px 2px #000000"}}>
              <h1 className={classes.title} style={{fontSize: "3.8vw"}}>{t.titleLong}</h1>
              <h2 className={classes.subtitle} style={{fontSize: "2.2vw"}}>{t.financed}</h2>
            </div>
          </GridItem>
        </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main)}>

        <div  id="project" />
        <SectionProject t={t} />

        <Divider />
        <SectionImagesScience />
        <Divider />

        <div  id="partners" />
        <SectionPartneri t={t} />

        <Divider />

        <div  id="news" />
        <SectionNews hr={t.hr} logged={logged}/>

        <Divider />
        <SectionImagesMeasure />
        <Divider />

        <div  id="contact" />
        <SectionContact t={t} ip={ip}/>

        <Divider />

        <div  id="login" />
        <SectionLogin logged={logged} setLogged={setLogged} ip={ip}/>

      </div>
      <Footer hr={t.hr}/>
    </div>
  );
}
